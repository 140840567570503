import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment } from "react";
import { MyCashBoxPng } from "../../../../assets/images";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";
import { getFormattedDiff } from "../../../../utils/getFormattedDiff";
import { PlaySessionType } from "../../../../types/PlaySession.type";
import { SoundButton } from "../../../../components/SoundButton";
import { useThemeContext } from "../../../../contexts/theme/useThemeContext";

interface ProfitLossReportModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

function getProfitLossInfo(userId: string, session: PlaySessionType) {
  const userIndex = session.users.findIndex((v) => v._id === userId);
  // 손익 보고서는 새로운 턴이 시작될 때 보여진다.
  // 손익 보고서의 현금은 현재 턴과 전전턴을 비교해야한다.
  // 이유는 현재턴의 정보는 이전턴에 수정된 정보이기 때문이다.
  const user = session.users[userIndex];
  if (!user) {
    return {
      diff_cash: 0,
      diff_stock_value: 0,
    };
  }
  const current_cash = user.turn_cash[user.turn_cash.length - 1]?.cash ?? 0;
  const prev_cash =
    user.turn_cash[user.turn_cash.length - 3]?.cash ??
    user.turn_cash[user.turn_cash.length - 2]?.cash ??
    0;

  // 보유 주식
  const current_stocks =
    user.stock_info[user.stock_info.length - 1]?.stocks ?? [];

  // 현재 주가
  const currStockPriceMap = session.stocks.reduce((acc, v) => {
    const { _id, price_info: _price_info } = v;
    const price_info = (_price_info ?? []).filter((v, _, arr) => {
      const result = !(arr.length >= 3 && v.turn === 0);
      return result;
    });
    const recentlyPriceInfo = price_info[price_info.length - 1];
    acc[_id] = recentlyPriceInfo?.price ?? 0;
    return acc;
  }, {} as Record<string, number>);
  // 이전 주가
  const prevStockPriceMap = session.stocks.reduce((acc, v) => {
    const { _id, price_info: _price_info } = v;
    const price_info = (_price_info ?? []).filter((v, _, arr) => {
      const result = !(arr.length >= 3 && v.turn === 0);
      return result;
    });
    const recentlyPriceInfo = price_info[price_info.length - 2];
    acc[_id] = recentlyPriceInfo?.price ?? 0;
    return acc;
  }, {} as Record<string, number>);

  const currentStockValue = current_stocks.reduce((acc, val) => {
    const price = currStockPriceMap[val._id] ?? 0;
    acc += val.amount * price;
    return acc;
  }, 0);
  const prevStockValue = current_stocks.reduce((acc, val) => {
    const price = prevStockPriceMap[val._id] ?? 0;
    acc += val.amount * price;
    return acc;
  }, 0);
  return {
    diff_cash: current_cash - prev_cash,
    diff_stock_value: currentStockValue - prevStockValue,
  };
}

export const ProfitLossReportModal: FC<ProfitLossReportModalProps> = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const theme = useThemeContext();
  const { session, userId = "" } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const { diff_cash, diff_stock_value } = getProfitLossInfo(userId, session);

  const totalDiff = diff_cash + diff_stock_value;

  const buttonColor = theme.profit_loss_report_color;
  const button_font_color = theme.button_font_color;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-[0.43]"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="min-h-full" style={{ padding: "140px 12.5px 0" }}>
              <div className="h-full">
                <div
                  className="rounded-t-lg bg-white h-full"
                  style={{ padding: "50px 17.5px 0" }}
                >
                  <div className="px-1 5">
                    <div className="border-b border-browngrey border-dashed pb-4 mb-5">
                      <div className="flex justify-center">
                        <div className="border-b border-browngrey border-dashed">
                          <p
                            className="pt-1 px-1 text-m5 font-medium text-darkBlueGray mb-2"
                            style={{
                              backgroundColor: "#edf2ff",
                            }}
                          >
                            손익 보고서
                          </p>
                          <div className="border-b border-browngrey border-dashed my-1"></div>
                        </div>
                      </div>
                      <div className="flex justify-between items-center mt-16">
                        <p className="text-m6 text-browngrey font-medium">
                          현금
                        </p>
                        <p className="text-m4 font-medium">
                          <strong
                            className={classNames({
                              "text-coral": diff_cash >= 0,
                              "text-dodgerBlue": diff_cash < 0,
                            })}
                          >
                            {getFormattedDiff({ price: diff_cash })}
                          </strong>
                          <span className="text-m6"> 원</span>
                        </p>
                      </div>
                    </div>
                    <div className="border-b border-browngrey border-dashed pb-4 mb-5">
                      <div className="flex justify-between items-center">
                        <p className="text-m6 leading-snug text-browngrey font-medium">
                          주식 <br />
                          평가금액
                        </p>
                        <p className="text-m4 font-medium">
                          <strong
                            className={classNames({
                              "text-coral": diff_stock_value >= 0,
                              "text-dodgerBlue": diff_stock_value < 0,
                            })}
                          >
                            {getFormattedDiff({ price: diff_stock_value })}
                          </strong>
                          <span className="text-m6"> 원</span>
                        </p>
                      </div>
                    </div>
                    <div className="pt-6">
                      <p
                        className="text-center leading-none"
                        style={{ fontSize: 65 / 2 }}
                      >
                        <strong
                          className={classNames({
                            "text-coral": totalDiff >= 0,
                            "text-dodgerBlue": totalDiff < 0,
                          })}
                        >
                          {getFormattedDiff({ price: totalDiff })}
                        </strong>
                        <span className="text-m5">원</span>
                      </p>
                    </div>
                  </div>
                  <div className="pb-2">
                    <SoundButton
                      className="blue-btn-mo w-full mt-10"
                      onClick={onClose}
                      style={{
                        backgroundColor: buttonColor,
                        color: button_font_color,
                      }}
                      playSound={shouldPlaySound}
                    >
                      닫기
                    </SoundButton>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    marginTop: -1,
                    backgroundImage: `url(${MyCashBoxPng})`,
                  }}
                  className="bg-bottom bg-cover"
                ></div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
