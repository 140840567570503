import { FC, useMemo } from "react";
import { StepHeader } from "./components/StepHeader";
import { Route, Routes } from "react-router-dom";
import { StockChart } from "./stock-chart";
import { FirstDecision } from "./first-decision";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";
import { PcBgPng } from "../../../../assets/images";
import { getExpiryData } from "../../../../utils/getExpiryData";
import { PersonalStatement } from "./personal-statement";
import { FreeDiscussion } from "./free-discussion";
import { FinalDecision } from "./final-decision";
import { StockDeal } from "./stock-deal";

interface Round1IndexProps {}

export const Round1Index: FC<Round1IndexProps> = () => {
  const session = usePlaySessionContext();
  const { endDates, orderUsers } = useMemo(() => {
    return getExpiryData(session.teamCode, session.session);
  }, [session]);

  return (
    <div
      className="w-full h-full flex justify-center items-center"
      style={{
        backgroundImage: `url(${PcBgPng})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: 800,
      }}
    >
      <div className="w-full h-full" style={{ maxWidth: 1280, height: 800 }}>
        <div style={{ padding: "24px 50px 0" }}>
          <StepHeader
            orders={orderUsers ? orderUsers.map((v) => v.name) : null}
            step={session.session.current_step}
            expiryTimestamp={endDates}
          />
        </div>
        <Routes>
          <Route path="/stock-chart" Component={StockChart} />
          <Route path="/first-decision" Component={FirstDecision} />
          <Route path="/personal-statement" Component={PersonalStatement} />
          <Route path="/free-discussion" Component={FreeDiscussion} />
          <Route path="/final-decision" Component={FinalDecision} />
          <Route path="/stock-deal" Component={StockDeal} />
        </Routes>
      </div>
    </div>
  );
};
