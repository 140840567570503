import { FC } from "react";
import { useThemeContext } from "../../../contexts/theme/useThemeContext";

interface ResultPageProps {}

export const ResultPage: FC<ResultPageProps> = () => {
  const theme = useThemeContext();
  const fontColor = theme.font_color;
  return (
    <div className="w-full h-full flex items-center justify-center flex-col bg-theme">
      <h1
        className="text-p3 text-white text-center"
        style={{ color: fontColor }}
      >
        교육과정이 종료되었습니다
      </h1>
    </div>
  );
};
